<template>
  <div>
    <Navbar />
    <div class="col-12 cookie">
      <h5 class="text-blue">{{ $t("dataSettings") }}</h5>
      <p>
        {{ $t("dataSettingsText") }}
      </p>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../components/shared/Navbar.vue";
import Footer from "../components/shared/Footer.vue";

export default {
  components: {
    Navbar,
    Footer,
  },
  created() {
    const lang = this.$i18n.locale === "tr" ? "" : this.$i18n.locale;
    location.href = `https://flinktax.de/${lang}/cookie`;
  },
};
</script>

<style lang="scss">
.cookie {
  padding: 160px 50px 110px 50px;
}
</style>
